import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

const monthNames = [
    "January", "February", "March", "April", "May", "June", 
    "July", "August", "September", "October", "November", "December"
];


function Sidebar() {
    const [isCollapsed, setIsCollapsed] = useState(true);
  
    return (
      <div className={`sidebar ${!isCollapsed ? 'collapsed' : ''}`}>
        <button className="toggle-button" onClick={() => setIsCollapsed(!isCollapsed)}>
          ☰
        </button>
        <ul className="menu">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/blog">Blog/Trending</Link></li>
          <li><Link to="/how-to">How To Use</Link></li>
          <li><Link to="/whats-new">Whats New</Link></li>
        </ul>
      </div>
    );
}


function BlogTrending() {
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [postContent, setPostContent] = useState('');

  // Fetch year folders
  useEffect(() => {
    const availableYears = ['2024'];
    setYears(availableYears);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = monthNames[currentDate.getMonth()];
    setSelectedYear(currentYear.toString());
    setSelectedMonth(currentMonth);
    loadPost(currentYear.toString(), currentMonth);
  }, []);

  const loadPost = async (year, month) => {
    try {
      const response = await import(`./Posts/${year}/${month}.txt`);
      const content = await fetch(response.default).then((res) => res.text());
      setPostContent(content);
    } catch (error) {
      setPostContent('Post not found.');
    }
  };

  const handleYearChange = (e) => {
    const year = e.target.value;
    setSelectedYear(year);
    setSelectedMonth('');
    setPostContent('');
  };

  const handleMonthChange = (e) => {
    const month = e.target.value;
    setSelectedMonth(month);
    loadPost(selectedYear, month);
  };

  return (
    <div className="blog-container">
        <Sidebar />
      <h1 className="blog-header">Blog/Trending</h1>

      <div className="dropdown-container">
        <div className="dropdownpicker">
          <label>Year:</label>
          <select value={selectedYear} onChange={handleYearChange}>
            <option value="">Select Year</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>

        {selectedYear && (
          <div className="dropdownpicker">
            <label>Month:</label>
            <select value={selectedMonth} onChange={handleMonthChange}>
              <option value="">Select Month</option>
              {monthNames.map((month) => (
                <option key={month} value={month}>
                  {month}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      <div className="post-content">
        <h2>Food trends in {selectedMonth} {selectedYear}</h2>
        <ReactMarkdown>{postContent}</ReactMarkdown>
      </div>
    </div>
  );
}

export default BlogTrending;
