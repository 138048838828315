import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import howToUse1 from './howToUse1.svg';
import howToUse2 from './howToUse2.svg';



function Sidebar() {
    const [isCollapsed, setIsCollapsed] = useState(true);
  
    return (
      <div className={`sidebar ${!isCollapsed ? 'collapsed' : ''}`}>
        <button className="toggle-button" onClick={() => setIsCollapsed(!isCollapsed)}>
          ☰
        </button>
        <ul className="menu">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/blog">Blog/Trending</Link></li>
          <li><Link to="/how-to">How To Use</Link></li>
          <li><Link to="/whats-new">Whats New</Link></li>
        </ul>
      </div>
    );
}

const HowToUse = () => {
  return (
    <div className="howTo-container">
        <Sidebar />
    
      <h1 className="howTo-header">How To Use</h1>
      
        <div className="howTo-image">
            <img src={howToUse1} alt="HowToUse1" className="howToUse-Img" />
        </div>

        <div className="howTo-image">
            <img src={howToUse2} alt="HowToUse2" className="howToUse-Img" />
        </div>
    </div>
  );
};

export default HowToUse;
