import React, { useState } from 'react';
import OrImage from './or-space.png';
import allCities from './all_cities.json';
import { getFunctions, httpsCallable } from 'firebase/functions';
import app from './firebaseConfig.js'; // import Firebase initialization


const restaurants = [
  "McDonald's", "Chick-fil-A", "Taco Bell", "Wendy's", "Burger King", "Subway",
  "Domino's Pizza", "Panera Bread", "Chipotle Mexican Grill", "Pizza Hut", "KFC",
  "Popeyes", "Arby's", "Little Caesars", "Panda Express", "Dairy Queen",
  "Jack in the Box", "Olive Garden", "Buffalo Wild Wings", "Jimmy John's",
  "Applebee's", "Chili's Grill & Bar", "Outback Steakhouse", "IHOP",
  "Red Lobster", "Cracker Barrel", "Five Guys", "Raising Cane's ",
  "Wingstop", "Texas Roadhouse", "The Cheesecake Factory", "Red Robin",
  "Qdoba Mexican Eats", "Hardee's", "Carl's Jr.", "Noodles & Company", "Shake Shack",
  "Firehouse Subs"
];

const functions = getFunctions(app);


function CitySearch() {
  const [city, setCity] = useState('');
  const [restaurant, setRestaurant] = useState('');
  const [filteredCities, setFilteredCities] = useState([]);
  const [showCard, setShowCard] = useState(false); // State for card visibility
  const [isCitySpecific, setIsCitySpecific] = useState(false);
  const [mustHave, setMustHave] = useState('');
  const [noHave, setNoHave] = useState('');
  
  // Filter cities based on user input
  const handleInputChange = (e) => {
    const input = e.target.value;
    setCity(input);
    if (input) {
      const filtered = allCities.filter(cityName =>
        cityName.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredCities(filtered);
    } else {
      setFilteredCities([]);
    }
  };

  // Select a city from the dropdown
  const handleCitySelect = (cityName) => {
    setCity(cityName);
    setFilteredCities([]);
  };

   // Function to get a restaurant suggestion using the Firebase function
   const handleSearch = async () => {
    setFilteredCities([]);
    if (!city) return; // Ensure a city is entered

    try {
  
      const getRestaurantSuggestion = httpsCallable(functions, 'getRestaurantSuggestion');
      const response = await getRestaurantSuggestion({ city, mustHave, noHave });

      console.log("Function response:", response.data);

      setRestaurant(response.data.restaurant);
      setIsCitySpecific(true);
      setShowCard(true); // Show the card when a suggestion is made
    } catch (error) {
      console.error("Error fetching suggestion:", error);
      setRestaurant("Sorry, we couldn't fetch a suggestion. Please try again.");
    }
  };

  // Function to close the card
  const handleCloseCard = () => {
    setShowCard(false);
    setCity('');
    setRestaurant(''); // Optionally reset the restaurant suggestion
    setNoHave('');
    setMustHave('');
  };

  return (
    <div className="container">
      {/*<button className="AdFreeButton">{'Go Ad Free'}</button>*/} 
      <h1 className="title">Find a Restaurant</h1>
      <div className="search-container">
        <input
          type="text"
          className="input"
          placeholder="Enter a city..."
          value={city}
          onChange={handleInputChange}
        />
        <button className="enterButton" onClick={handleSearch}>{'>'}</button>

        {filteredCities.length > 0 && (
          <ul className="dropdown">
            {filteredCities.map((cityName, index) => (
              <li key={index} className="dropdown-item" onClick={() => handleCitySelect(cityName)}>
                {cityName}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="form-group">
        <label className="optional-label">Optional</label>
        <input
          type="text"
          className="input"
          placeholder="No's (ex. Italian, Pizza)"
          value={noHave}
          onChange={(e) => setNoHave(e.target.value)}
        />
      </div>

      <div className="form-group">
        <label className="optional-label">Optional</label>
        <input
          type="text"
          className="input"
          placeholder="Must's (ex. Vegan, Burgers)"
          value={mustHave}
          onChange={(e) => setMustHave(e.target.value)}
        />
      </div>

      <div className="image-container">
        <img src={OrImage} alt="OR" className="Or-image" />
      </div>

      <button className="generic-button" onClick={() => {
        const randomRestaurant = restaurants[Math.floor(Math.random() * restaurants.length)];
        setRestaurant(randomRestaurant);
        setIsCitySpecific(false);
        setShowCard(true); // Show the card when a generic restaurant is selected
      }}>
        Generic Place
      </button>

      {showCard && restaurant && (
        <div className="card">
          <button className="close-button" onClick={handleCloseCard}>X</button>
          {isCitySpecific ? (
            <>
              <p><b>In {city}, We Suggest:</b><br/><br/>{restaurant}</p>
              <div className="button-container">
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(restaurant)},${encodeURIComponent(city)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="directions-button">Get Directions</button>
                </a>
              </div>
            </>
          ) : (
            <p><b>We Suggest:</b><br/><br/>{restaurant}</p>
          )}
        </div>
      )}

    </div>
  );
}

export default CitySearch;
