import React, { useState } from 'react';
import { Link } from 'react-router-dom';


function Sidebar() {
    const [isCollapsed, setIsCollapsed] = useState(true);
  
    return (
      <div className={`sidebar ${!isCollapsed ? 'collapsed' : ''}`}>
        <button className="toggle-button" onClick={() => setIsCollapsed(!isCollapsed)}>
          ☰
        </button>
        <ul className="menu">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/blog">Blog/Trending</Link></li>
          <li><Link to="/how-to">How To Use</Link></li>
          <li><Link to="/whats-new">Whats New</Link></li>
        </ul>
      </div>
    );
}

const WhatsNew = () => {
  return (
    <div className="whatsNew-container">
        <Sidebar />
    
      <h1 className="whatsNew-header">Whats New!</h1>

      <p style={{fontSize: '25px', paddingLeft: '10%', paddingRight: '10%'}}>
        From the developers who made <b><i>Random Eatz</i></b> comes new suggestion webapp
        platform called <b><i>NextQuest.</i></b> <b><i>NextQuest</i></b> will provide video game suggestions based
        on games you already love. Most suggestion platform just suggest games that are just in the same
        genre or shows the same game you said you liked. But <b><i>NextQuest</i></b> is different, it uses AI to find similar games
        based on things like gameplay style, feel, genre, story and more to help you find your <b><i>NextQuest.</i></b>
      </p>

    </div>
  );
};

export default WhatsNew;
