import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import CitySearch from './CitySearch';
import OrderSearch from './OrderSearch';
import randomEatzLogo from './randomEatzLogo2.svg';
import PrivacyPolicy from './PrivacyPolicy';
import BlogTending from './BlogTrending';
import HowToUse from './HowToUse';
import WhatsNew from './WhatsNew';


function Sidebar() {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <div className={`sidebar ${!isCollapsed ? 'collapsed' : ''}`}>
      <button className="toggle-button" onClick={() => setIsCollapsed(!isCollapsed)}>
        ☰
      </button>
      <ul className="menu">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/blog">Blog/Trending</Link></li>
        <li><Link to="/how-to">How To Use</Link></li>
        <li><Link to="/whats-new">Whats New</Link></li>
      </ul>
    </div>
  );
}


function Home() {

  return (
    <div className="container">
      {/*<button className="AdFreeButton">{'Go Ad Free'}</button>*/}

      <Sidebar />

      <div className="header-image">
        <img src={randomEatzLogo} alt="RandomEatz" className="Title-Img" />
      </div>
      
      {/* Link to navigate to the city search page */}
      <Link to="/city-search">
        <button className="button">Random Place</button>
      </Link>
      <Link to="/order-search">
        <button className="button">Random Order</button>
      </Link>
      <Link to="/privacy-policy">Privacy Policy</Link>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/city-search" element={<CitySearch />} />
        <Route path="/order-search" element={<OrderSearch />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/blog" element={<BlogTending/>} />
        <Route path="/how-to" element={<HowToUse/>} />
        <Route path="/whats-new" element={<WhatsNew/>} />
      </Routes>
    </Router>
  );
}

export default App;
